export const generateGoogleMapsLink = (customer) => {
  const { zip, street, streetNumber } = customer;
  const fullAddress = `${street} ${streetNumber}, ${zip}`;
  const encodedAddress = encodeURIComponent(fullAddress);
  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
};

export const startTeamsCall = (phoneNumber) => {
  const teamsCallLink = `https://teams.microsoft.com/l/call/0/0?users=,4:${phoneNumber}`;
  window.location.href = teamsCallLink;
};

// export const isValidIBAN = (iban) => {
//   const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{11,30}$/;
//   iban = iban.replace(/\s+/g, '');
//   if (!ibanRegex.test(iban)) return false;

//   const rearranged = iban.slice(4) + iban.slice(0, 4);
//   const numericIBAN = rearranged.replace(/[A-Z]/g, (char) => char.charCodeAt(0) - 55);

//   let remainder = 0;
//   for (let i = 0; i < numericIBAN.length; i += 7) {
//     remainder = parseInt(remainder.toString() + numericIBAN.slice(i, i + 7), 10) % 97;
//   }

//   return remainder === 1;
// };
