<template>
  <b-card :title="$t('state')" class="mb-4">
    <b-card-text class="overview-card-grid-container">
      <div>{{ $t('inCharge') }}</div>
      <div
        horizontal
        :label="$t('inCharge')"
        label-for="inCharge"
        label-class="text-md-right"
        :label-cols="4"
        breakpoint="md"
      >
        <multiselect
          class="custom-multiselect"
          v-model="project.sales.inCharge"
          :options="users"
          :custom-label="(user) => user.firstname + ' ' + user.lastname"
          :placeholder="$t('inCharge')"
          track-by="id"
          label="name"
          :allow-empty="true"
        ></multiselect>
      </div>
      <div>{{ $t('phase') }}</div>
      <div
        horizontal
        :label="$t('phase')"
        label-for="phase"
        label-class="text-md-right"
        :label-cols="4"
        breakpoint="md"
      >
        <multiselect
          class="custom-multiselect"
          :placeholder="$t('phase')"
          v-model="project.sales.phase"
          id="salesPhase"
          :options="getEnumValues('SalesPhase')"
          :allow-empty="false"
          :show-labels="false"
          deselectLabel
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <b-badge :variant="salesPhaseColor(option)">
              {{ $t(option) }}
            </b-badge>
          </template>
          <template slot="option" slot-scope="{ option }">
            <b-badge :variant="salesPhaseColor(option)">
              {{ $t(option) }}
            </b-badge>
          </template>
        </multiselect>
      </div>
      <div>{{ $t('state') }}</div>
      <div
        horizontal
        :label="$t('state')"
        label-for="state"
        label-class="text-md-right"
        :label-cols="4"
        breakpoint="md"
      >
        <multiselect
          :placeholder="$t('phase')"
          v-model="project.sales.state"
          id="salesPhase"
          :options="currentStateOptions"
          :allow-empty="false"
          :show-labels="false"
          deselectLabel
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <b-badge :variant="salesStateColor(option)">
              {{ $t(option) }}
            </b-badge>
          </template>
          <template slot="option" slot-scope="{ option }">
            <b-badge :variant="salesStateColor(option)">
              {{ $t(option) }}
            </b-badge>
          </template>
        </multiselect>
      </div>
      <div>{{ $t('callState') }}</div>
      <div
        class="custom-multiselect"
        horizontal
        :label="$t('callState')"
        label-for="callState"
        label-class="text-md-right"
        :label-cols="4"
        breakpoint="md"
      >
        <multiselect
          :placeholder="$t('callState')"
          v-model="project.sales.callState"
          id="callState"
          :options="getEnumValues('SalesCallState')"
          :allow-empty="false"
          :show-labels="false"
          deselectLabel
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <b-badge :variant="callStateColor(option)">
              {{ $t(option) }}
            </b-badge>
          </template>
          <template slot="option" slot-scope="{ option }">
            <b-badge :variant="callStateColor(option)">
              {{ $t(option) }}
            </b-badge>
          </template>
        </multiselect>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateGoogleMapsLink, startTeamsCall } from '@/helpers/integration';
import Multiselect from 'vue-multiselect';

import {
  getYesNoStateColor,
  getDocumentStateColor,
  getProjectStateColor,
  getSalesPhaseColor,
  getSalesStateColor,
  getCallStateColor,
} from '@/helpers/colors';
export default {
  components: {
    Multiselect,
  },
  props: {
    project: { type: Object },
    projectNumber: null,
  },
  watch: {
    'project.sales.phase': function(newPhase) {
      this.updateStateOptions(newPhase);
    },
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchClients',
      'fetchEmployersByClient',
      'fetchEmployers',
      'fetchUsers',
    ]),
    generateGoogleMapsLink,
    startTeamsCall,
    salesPhaseColor(state) {
      return getSalesPhaseColor(state);
    },
    salesStateColor(state) {
      return getSalesStateColor(state);
    },
    async updateStateOptions(phase) {
      let enumType;
      switch (phase) {
        case 'SALES_PHASE_LEAD':
          enumType = 'LeadState';
          break;
        case 'SALES_PHASE_QUALIFICATION':
          enumType = 'QualificationState';
          break;
        case 'SALES_PHASE_PLANNING':
          enumType = 'PlanningState';
          break;
        case 'SALES_PHASE_SALESCALL_1':
          enumType = 'SalesCall1State';
          break;
        case 'SALES_PHASE_TECHNICAL_INSPECTION':
          enumType = 'TechnicalInspectionState';
          break;
        case 'SALES_PHASE_SALESCALL_2':
          enumType = 'SalesCall2State';
          break;
        case 'SALES_PHASE_SOLD':
          enumType = 'SoldState';
          break;
        case 'SALES_PHASE_LOST':
          enumType = null;
          break;
        default:
          enumType = null;
      }

      if (enumType) {
        this.currentStateOptions = this.getEnumValues(enumType);
      } else {
        this.currentStateOptions = [];
      }
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    documentStateColor(state) {
      return getDocumentStateColor(state);
    },
    projectStateColor(state) {
      return getProjectStateColor(state);
    },
    callStateColor(state) {
      return getCallStateColor(state);
    },
  },

  data() {
    return {
      users: [],
      currentStateOptions: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['getEnumValues', 'getClients', 'getEmployers', 'getUsers']),
    employers() {
      return this.getEmployers.filter((employer) => {
        if (
          employer.client &&
          this.project.client &&
          employer.client.id === this.project.client.id
        ) {
          return employer;
        }
      });
    },
  },
  async mounted() {
    await this.fetchClients();
    await this.fetchEmployers();
    await this.fetchEnumValues('SalesPhase');
    await this.fetchEnumValues('SalesCall1State');
    await this.fetchEnumValues('LeadState');
    await this.fetchEnumValues('QualificationState');
    await this.fetchEnumValues('QualificationState');
    await this.fetchEnumValues('PlanningState');
    await this.fetchEnumValues('SalesCall1State');
    await this.fetchEnumValues('TechnicalInspectionState');
    await this.fetchEnumValues('SalesCall2State');
    await this.fetchEnumValues('SoldState');
    await this.fetchEmployersByClient(this.getCurrentUser.client.id);
    await this.fetchUsers({
      page: 0,
      pageSize: 100,
      sortField: 'lastname',
      sortOrder: 1,
      filters: {},
    });
    if (this.getUsers) {
      this.users = this.getUsers;
    }
    this.updateStateOptions(this.project.sales.phase);
  },
};
</script>
<style scoped lang="scss">
.overview-card-grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 0.5em;
  align-items: center;
}

.overview-card-grid-container > div {
  word-wrap: break-word;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}

.overview-card-grid-container > div:first-child {
  padding-right: 1em;
}

.custom-multiselect {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.overview-card-grid-container > div.multiselect-container {
  grid-column: 2 / 3;
  width: 33%;
}

.overview-card-grid-container > div > a {
  text-decoration: none;
  color: #007bff;
  margin: 0;
}

a {
  text-decoration: none;
  color: #007bff;
  margin-left: 5px;
}

a:hover {
  text-decoration: underline;
}
</style>
